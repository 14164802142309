import { template as template_7e3b357d9cec4e71afcd54a95ac5ce4e } from "@ember/template-compiler";
const FKLabel = template_7e3b357d9cec4e71afcd54a95ac5ce4e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
