import { template as template_87a60853a3c64be3a50faa0e89b5ac38 } from "@ember/template-compiler";
const FKControlMenuContainer = template_87a60853a3c64be3a50faa0e89b5ac38(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
