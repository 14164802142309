import { template as template_b2ffc1c9f859420880002120b904d709 } from "@ember/template-compiler";
const WelcomeHeader = template_b2ffc1c9f859420880002120b904d709(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
